<template>
  <div class="w-full h-full flex flex-col">
    <div style="height: 100%" class="my-10" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div class="w-full flex flex-col my-3" v-else>
      <div class="w-full flex flex-col p-4 bg-ghostWhite">
        <div class="text-base text-justify mb-4">
          The virtual NUBAN account is a Paystack service and functions more
          like your regular bank accounts (You can transfer funds from any
          regular bank account to your virtual account). To process employee
          payroll on Cloudenly, you will need to setup a Virtual NUBAN account.
          When you setup, a ten digit NUBAN account number will be created for
          you in any of the participating Nigerian banks.
        </div>
        <div
          class="border border-romanSilver border-dashed h-0 w-full flex flex-grow my-4"
        />
        <div class="w-full">
          <div class="text-base text-justify my-4">
            Please note that Paystack charges a processing fee of 0.75% of the
            transaction amount capped at N100 each time you fund your account.
          </div>
          <div class="text-base text-justify my-4">
            Fund transfers to your virtual NUBAN account appear in your
            available balance within 24hrs. Before then, the net amount
            transferred will appear under 'Unsettled Balance'.
          </div>
        </div>
      </div>

      <span
        v-if="!nuban.isSetup"
        class="flex mt-3 w-auto cursor-pointer"
        @click="setUpVirtual()"
      >
        <Icon icon-name="cash" size="s" class="text-flame" />
        <p class="text-flame text-base font-bold ml-2">
          Virtual NUBAN Accounts Setup
        </p>
      </span>

      <div v-if="nuban.isSetup">
        <card class="py-4 px-2 my-6">
          <div class="flex flex-grow my-4 p-1">
            <p class="text-flame text-base font-semibold mx-1">
              NUBAN Account Details
            </p>
          </div>
          <div
            class="flex w-full my-3 justify-end"
            v-if="Object.entries(sweepAccount).length > 0"
          >
            <div class="flex gap-5 p-1">
              <div
                class="flex gap-2 cursor-pointer"
                @click="checkPrivilege('view sweep account')"
              >
                <span class="flex flex-col justify-center">
                  <p class="text-sm">View Sweep Account</p>
                </span>
                <icon
                  icon-name="icon-info"
                  class="cursor-pointer"
                  size="xs"
                  v-tooltip.bottom="
                    `<p>Your sweep account allows you transfer</p> <p>back your virtual NUBAN
              balance to your regular bank account. </p> A transaction fee of N200 applies.`
                  "
                />
              </div>
              <div class="flex">
                <Button
                  class="bg-dynamicBackBtn font-semibold text-white text-center"
                  width="8rem"
                  @click="checkPrivilege('transfer funds')"
                >
                  Transfer
                </Button>
              </div>
            </div>
          </div>

          <div class="flex w-full my-3 justify-end" v-else>
            <div class="flex">
              <Button
                class="bg-dynamicBackBtn font-semibold text-white text-center"
                @click="checkPrivilege('create sweep account')"
              >
                Set-up Sweep Account
              </Button>
            </div>
          </div>

          <card class="py-2 px-2 my-4">
            <div class="w-full flex">
              <div class="flex flex-col w-1/5 justify-center border-r pr-5">
                <p class="text-xs p-1 font-black uppercase text-romanSilver">
                  Account Name
                </p>
                <p class="text-sm p-1 my-2">
                  {{ AccountsData[0].accountName }}
                </p>
              </div>
              <div class="flex flex-col w-1/5 justify-center px-5 border-r">
                <p class="text-xs p-1 font-black uppercase text-romanSilver">
                  Bank Name
                </p>
                <p class="text-sm p-1 my-2">
                  {{ AccountsData[0].bankName }}
                </p>
              </div>
              <div class="flex flex-col w-1/5 justify-center px-5 border-r">
                <p class="text-xs p-1 font-black uppercase text-romanSilver">
                  Account Number
                </p>
                <p class="text-sm p-1 my-2">
                  {{ AccountsData[0].accountNumber }}
                </p>
              </div>
              <div
                class="w-1/5 flex flex-col w-1/5 justify-center px-5 border-r"
              >
                <p class="text-xs p-1 font-black uppercase text-romanSilver">
                  Unsettled Balance
                </p>
                <div class="flex my-2 gap-2">
                  <div
                    class="py-1 px-2 border flex flex-col justify-center bg-ghostWhite rounded-md"
                  >
                    <p class="text-xs">NGN</p>
                  </div>
                  <div
                    class="py-1 px-2 border flex flex-col text-sm justify-center bg-ghostWhite rounded-md"
                  >
                    {{ AccountsData[0].unsettledBalance }}
                  </div>
                </div>
              </div>
              <div class="w-1/5 flex flex-col justify-center px-5">
                <p class="text-xs p-1 font-black uppercase text-romanSilver">
                  Available Balance
                </p>
                <div class="flex w-full my-2 gap-2">
                  <div
                    class="py-1 px-2 border flex flex-col justify-center bg-ghostWhite rounded-md"
                  >
                    <p class="text-xs">NGN</p>
                  </div>
                  <div
                    class="py-1 px-2 border flex flex-col text-sm justify-center bg-ghostWhite rounded-md"
                  >
                    {{ AccountsData[0].settledBalance }}
                  </div>
                </div>
              </div>
            </div>
          </card>

          <div
            class="w-full border border-dashed my-4"
            style="border-width: 0.5px"
          />

          <div class="w-full flex p-1">
            <div class="flex flex-grow">
              <span
                :class="
                  AccountsData[0].isActive
                    ? 'dot dot-circle success mt-1'
                    : 'dot dot-circle secondary mt-1'
                "
                style="width: 6px; height: 6px"
              />
              <span class="text-sm mx-2" style="margin-top: -0.2%">
                {{ AccountsData[0].isActive ? "Active" : "In Active" }}
              </span>
            </div>
            <div class="flex flex-end gap-5">
              <div
                class="flex cursor-pointer"
                @click="routePage(AccountsData[0].id)"
              >
                <Icon
                  icon-name="icon-eye"
                  size="xms"
                  class="mr-2 text-blueCrayola"
                />
                <p class="text-sm" style="margin-top: 1.3%">View History</p>
              </div>

              <div
                class="flex cursor-pointer"
                @click="actionNuban(AccountsData[0].isActive)"
              >
                <icon
                  icon-name="icon-unavailable"
                  size="xms"
                  class="mr-2 text-carrotOrange"
                />
                <p class="text-sm" style="margin-top: 1.3%">
                  {{ AccountsData[0].isActive ? "Deactivate" : "Activate" }}
                </p>
              </div>

              <div
                class="flex cursor-pointer"
                @click="checkPrivilege('delete nuban', AccountsData[0].id)"
              >
                <icon icon-name="delete" size="xsm" class="mr-1 text-desire" />
                <p class="text-sm" style="margin-top: 1.3%">Delete</p>
              </div>
            </div>
          </div>
        </card>
      </div>

      <RightSideBar v-if="openModal" :show-footer="false" :loading="loader">
        <template v-slot:title>
          <p class="text-darkPurple">New Virtual NUBAN Account</p>
        </template>

        <p class="text-base font-semibold my-4">
          Please enter your regular bank details to generate a virtual NUBAN
          account.
        </p>

        <div class="w-full p-4 mt-4 mb-6 bg-ghostWhite text-base">
          Your bank information entered below will only be used for verification
          purposes only.
        </div>

        <div class="my-10">
          <checkbox
            label="Use this account details as your Sweep Account"
            checkbox-size="height:16px; width:16px; margin:4px 7px 8px 8px;"
            label-style="color:#333333; "
            v-model="setSweepAccount"
          />
        </div>

        <ValidationObserver v-slot="{ invalid, reset }">
          <form @submit.prevent="generateNuban" @reset.prevent="reset">
            <div class="flex flex-col items-start gap-3">
              <CText
                class="w-full mb-2"
                placeholder="--Enter--"
                label="Organisation Name"
                :rules="['required']"
              />

              <CSelect
                class="w-full mb-2"
                placeholder="--Select--"
                label="Bank"
                :rules="['required']"
                :options="banks"
                v-model="customer.bankCode"
              />

              <CText
                class="w-full mb-2"
                placeholder="--Enter--"
                label="Account Number"
                :rules="numberRule"
                v-model="customer.accountNumber"
                type="number"
              />

              <small v-if="loadingAccount" class="text-xs">Loading...</small>
              <small class="text-red-700 font-bold" v-if="error">{{
                `${error}`
              }}</small>
              <small
                class="text-flame font-bold"
                v-if="openModal && accountName"
                >{{ ` ${accountName} ` }}</small
              >
              <div class="flex flex-grow mb-5 mt-8">
                <Button
                  :disabled="invalid"
                  :class="
                    invalid
                      ? 'bg-gray-200 text-gray-500 rounded'
                      : 'bg-dynamicBackBtn text-white rounded'
                  "
                >
                  Generate nuban
                </Button>
                <button
                  type="button"
                  @click="closeNubanRightSideBar"
                  class="font-semibold rounded-md ml-5 p-2 text-center radius buttonFocus"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </RightSideBar>
      <Modal v-if="dialogDel">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <div v-if="nuban.isActive" class="mb-5">
              <div class="w-full px-1">
                <p class="text-2xl font-black">Deactivate</p>
              </div>
              <div
                class="w-full border-2 border-dashed my-4"
                style="height: 0.2px"
              />
              Account deactivation is temporal. You will not be able to complete
              payroll processing when your NUBAN account is deactivated.
            </div>
            <div v-else class="font-bold ml-4 my-5 text-center">
              <p>Are you sure you want to activate this Account?</p>
              <small class="text-flame"
                >Note: Your payroll payment will be settled from this Account
              </small>
            </div>
            <div class="flex">
              <Button
                class="bg-dynamicBackBtn text-white"
                @click="changeStatus"
              >
                Yes, {{ nuban.isActive ? "Deactivate" : "Activate" }}
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                @click="dialogDel = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Modal v-if="dialogDelete">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <div class="w-full px-1">
              <p class="text-2xl font-black">Delete</p>
            </div>
            <div
              class="w-full border-2 border-dashed my-4"
              style="height: 0.2px"
            />
            <div class="ml-4 my-1 text-base flex flex-col gap-1">
              <p>
                You will lose ALL available balance and will no longer be able
                to transact with this account once deleted.
              </p>
              <p class="font-bold">
                Please ensure you have transferred your available balance using
                the sweep account feature.
              </p>
              <p>Confirm you want to proceed to delete.</p>
            </div>
            <div class="flex mt-4">
              <Button
                class="bg-dynamicBackBtn text-white"
                @click="authenticateUser"
              >
                Yes, Delete
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                @click="dialogDelete = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
    </div>
    <view-sweep-account
      :account-info="sweepAccount"
      :banks="banks"
      v-if="openSweepAccount"
      @close="reload"
    />
    <transfer-modal
      v-if="openTransferModal"
      :total-balance="AccountsData[0].settledBalance"
      :details="sweepAccount"
      @close="reload"
    />
    <set-up-sweep-modal v-if="openSweepSetUp" :banks="banks" @close="reload" />
    <Modal v-if="showPasswordModal">
      <Card class="p-5 flex flex-col max-w-lg">
        <div class>
          <p class="font-extrabold text-lg my-5 text-left">
            You need to be authenticated to proceed.
          </p>
          <p class="text-romanSilver font-semibold text-left mt-5 mb-3">
            Type in your password
          </p>
          <div class="my-5">
            <CText
              placeholder="--Enter--"
              label="Password"
              class="w-full mt-2"
              v-model="password"
              :type="decryptPassword ? '' : 'password'"
            />
            <checkbox
              label="Show Password"
              checkbox-size="width:16px; height: 16px; margin-top:4px"
              class="flex justify-start items-left my-4"
              v-model="decryptPassword"
            />
          </div>
          <div class="flex justify-end">
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              width="7.875rem"
              @click="showPasswordModal = false"
            >
              Cancel
            </Button>
            <Button
              width="7.875rem"
              class="bg-dynamicBackBtn ml-4 text-white"
              :disabled="disableBtn"
              @click="checkPassword"
            >
              Submit
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState } from "vuex";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Checkbox from "@/components/Checkbox";
import RightSideBar from "@/components/RightSideBar";
import Modal from "@/components/Modal";
import Card from "@/components/Card";
import Button from "@/components/Button";
import ViewSweepAccount from "./sideActions/viewSweepAccount";
import TransferModal from "./sideActions/transferModal";
import setUpSweepModal from "./sideActions/setUpSweepModal";

export default {
  components: {
    RightSideBar,
    CText,
    CSelect,
    Card,
    ValidationObserver,
    TransferModal,
    Checkbox,
    Modal,
    Button,
    ViewSweepAccount,
    setUpSweepModal,
  },

  data() {
    return {
      openModal: false,
      openSweepAccount: false,
      openTransferModal: false,
      loadingBar: false,
      dialogDel: false,
      dialogDelete: false,
      loader: false,
      nubanPolling: null,
      pollingAttempts: 0,
      setSweepAccount: false,
      openSweepSetUp: false,
      showPasswordModal: false,
      decryptPassword: false,
      password: "",
      disableBtn: false,
      error: "",
      numberRule: [
        "required",
        {
          name: "number",
          rule: (v) => this.validateNumber(v),
        },
      ],
      AccountsHeader: [
        { title: "Account Name", value: "accountName" },
        { title: "Account Number", value: "accountNumber" },
        { title: "Bank Name", value: "bankName" },
        {
          title: "Unsettled Balance(₦)",
          value: "unsettledBalance",
          tooltip: "Actual amount funded - Processing fee",
        },
        {
          title: "Available Balance(₦)",
          value: "settledBalance",
          tooltip: "Amount available for salary payment",
        },
        { title: "Status", value: "isActive" },
        { title: "default", value: "id", image: true },
      ],
      AccountsData: [],
      nuban: {},
      customer: {
        email: null,
        phone: null,
        bankCode: null,
        accountNumber: null,
        orgId: null,
      },
      sweepAccountPayload: {
        userId: "",
        orgId: "",
        bankCode: "",
        accountNumber: "",
        sweepId: null,
      },
      accountName: null,
      loadingAccount: false,
      loadingData: true,
      sweepAccount: {},
      loading: true,
      nubanId: "",
    };
  },
  methods: {
    async checkPrivilege(data, id) {
      switch (data) {
        case "create sweep account":
          try {
            await this.$handlePrivilege("bankAccountHR", "setUpSweepAccount");
            this.openSweepSetUp = true;
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;

        case "view sweep account":
          try {
            await this.$handlePrivilege("bankAccountHR", "viewSweepAccount");
            this.openSweepAccount = true;
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;

        case "delete nuban":
          try {
            await this.$handlePrivilege(
              "bankAccountHR",
              "deleteVirtualNubanAccount"
            );
            this.triggerDelete(id);
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;

        case "transfer funds":
          try {
            await this.$handlePrivilege("bankAccountHR", "transferFunds");
            this.openTransferModal = true;
          } catch (error) {
            this.$toasted.error(
              "You do not have permission to perform this task",
              {
                duration: 5000,
              }
            );
          }
          break;
        default:
          break;
      }
    },

    triggerDelete(id) {
      this.nubanId = id;
      this.dialogDelete = true;
    },

    authenticateUser() {
      this.showPasswordModal = true;
    },

    checkPassword() {
      this.disableBtn = true;
      this.$_checkPassword({
        userId: this.$AuthUser.id,
        password: this.password,
      })
        .then(() => {
          this.showPasswordModal = false;
          this.password = "";
          this.decryptPassword = false;
          this.disableBtn = false;
          this.dialogDelete = false;
          this.deleteNuban();
        })
        .catch(() => {
          this.$toasted.error("Password is incorrect", { duration: 5000 });
          this.disableBtn = false;
        });
    },

    deleteNuban() {
      this.$_deleteNubanAccount(this.nubanId)
        .then(() => {
          this.$toasted.success("Nuban account deleted successfully", {
            duration: 3000,
          });
          this.reload();
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, {
            duration: 3000,
          });
        });

      this.$_deleteSweepAccount(this.sweepAccount.id);
    },

    async actionNuban(isActive) {
      if (isActive) {
        try {
          await this.$handlePrivilege(
            "bankAccountHR",
            "deactivateVirtualNubanAccount"
          );
          this.dialogDel = true;
        } catch (error) {
          this.$toasted.error(
            "You do not have permission to perform this task",
            {
              duration: 5000,
            }
          );
        }
      } else {
        try {
          await this.$handlePrivilege(
            "bankAccountHR",
            "activateVirtualNubanAccount"
          );
          this.dialogDel = true;
        } catch (error) {
          this.$toasted.error(
            "You do not have permission to perform this task",
            {
              duration: 5000,
            }
          );
        }
      }
    },

    async setUpVirtual() {
      try {
        await this.$handlePrivilege(
          "bankAccountHR",
          "setupVirtualNubanAccount"
        );
        this.openModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    validateNumber(value) {
      this.error = "";
      this.accountName = null;
      if (value.toString().length === 10) {
        if (this.accountName === null) {
          this.loadingAccount = true;
        }
        this.handleAccountChange(value);
        return true;
      }
      return "Account number must be 10 numbers";
    },

    handleAccountChange(value) {
      this.$_checkAccountNo({
        bankCode: this.customer.bankCode,
        acctNumber: value,
      })
        .then((result) => {
          if (result.status === 200) {
            this.error = "";
            this.loadingAccount = false;
            this.accountName = result.data.data.account_name;
          }
        })
        .catch(() => {
          this.loadingAccount = false;
          this.accountName = null;
          this.error = "Incorrect Account number";
        });
    },

    generateNuban() {
      this.customer.orgId = this.$OrgData.id;
      this.customer.email = this.$OrgData.email;
      this.customer.phone = this.$OrgData.phoneNo;

      if (this.setSweepAccount) {
        this.$_addNubanAccount(this.customer)
          .then(() => {
            this.createSweepAccount();
          })
          .catch(() => {
            this.$toasted.error(
              "Virtual Account could not be created. Kindly add a phone number in your organisation details and attempt again.",
              {
                duration: 6000,
              }
            );
          });
      } else {
        this.$_addNubanAccount(this.customer)
          .then(() => {
            this.reload();
            this.openModal = false;
            this.$toasted.success("Virtual Account creation in progress.", {
              duration: 3000,
            });
          })
          .catch(() => {
            this.$toasted.error(
              "Virtual Account could not be created. Kindly add a phone number in your organisation details and attempt again.",
              {
                duration: 6000,
              }
            );
          });
      }
    },

    createSweepAccount() {
      this.sweepAccountPayload.bankCode = this.customer.bankCode;
      this.sweepAccountPayload.accountNumber = this.customer.accountNumber;
      this.sweepAccountPayload.userId = this.$AuthUser.id;
      this.sweepAccountPayload.orgId = this.$OrgData.id;
      this.$_addSweepAccount(this.sweepAccountPayload)
        .then(() => {
          this.reload();
          this.openModal = false;
          this.$toasted.success("Virtual Account created successfully.", {
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toasted.error(
            "Virtual Account could not be created. Kindly add a phone number in your organisation details and attempt again.",
            {
              duration: 6000,
            }
          );
        });
    },

    async getNubanaccount() {
      this.$_getNubanAccount().then((result) => {
        this.nuban = result.data.data;
        this.nuban.settledBalance = Number(this.nuban.settledBalance)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.nuban.unsettledBalance = Number(this.nuban.unsettledBalance)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        this.AccountsData = [this.nuban];
      });
    },

    changeStatus() {
      const payload = {
        accountId: this.nuban.id,
        isActive: !this.nuban.isActive,
      };
      const status = this.nuban.isActive ? "deactivated" : "activated";
      this.$_updateNubanStatus(payload)
        .then(() => {
          this.dialogDel = false;
          this.reload();
          this.$toasted.success(`Account ${status} successfully`, {
            duration: 6000,
          });
        })
        .catch(() => {
          this.$toasted.error("An error occured.", {
            duration: 6000,
          });
        });
    },
    closeNubanRightSideBar() {
      this.accountName = null;
      this.openModal = false;
      Object.keys(this.customer).forEach((key) => {
        this.customer[key] = null;
      });
    },

    async routePage(id) {
      try {
        await this.$handlePrivilege("bankAccountHR", "viewTransactionHistory");
        this.$router.push({
          name: `Transaction History`,
          params: { id: `${id}` },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async getSweepAccount() {
      try {
        const response = await this.$_getSweepAccount();
        this.sweepAccount = response.data.data;
        this.loading = false;
      } catch (error) {
        this.$toasted.error(error.response, {
          duration: 5000,
        });
      }
    },

    async reload() {
      this.openSweepAccount = false;
      this.openTransferModal = false;
      this.openSweepSetUp = false;
      await this.getNubanaccount();
      await this.$store.dispatch("getBanks");
      await this.getSweepAccount();
    },
  },
  computed: {
    ...mapState({
      banks: (state) =>
        state.Banks.map((bank) => ({ id: bank.code, name: bank.name })),
    }),
  },
  async mounted() {
    await this.getNubanaccount();
    await this.$store.dispatch("getBanks");
    await this.getSweepAccount();
  },
};
</script>
